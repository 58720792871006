<template>
  <div class="home">
<!--    <img alt="Vue logo" src="../assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
      Loading...
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'Home',
    // components: {
    //     HelloWorld
    // },
    data() {
        return {
          isAndroid: false,
          isIOS: false
        }
    },
    mounted() {
        const u = navigator.userAgent;
        this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        this.isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (this.isIOS || this.isAndroid) {
            window.location.href = 'https://www.mianjuyuehui.net/webs/down-page'
        } else {
            this.$router.push({name: 'WebDownload'})
        }
    }
}
</script>
