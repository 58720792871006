<template>
    <div id="app" >
        <router-view/>
    </div>
</template>

<script>
export default {
    mounted() {
        // document.title = "面具约会"
        this.initHtmlFontSize()
        window.onresize = () => {
            this.initHtmlFontSize()
        }
    },
    data() {
        return {
        }
    },
    methods: {
        initHtmlFontSize() {
            // console.log('ss', window.innerWidth)
            const fontsize = window.innerWidth / 375 * 16
            // this.appStyle = { 'font-size': fontsize+'px' }
            const html = document.querySelector('html')
            html.style.fontSize = fontsize + 'px'
        },
    }
}
</script>

<style lang="scss">
#app {
    text-align: center;
    height: 100%;
    font-size: 16px;
}
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}
</style>
