import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/app',
        name: 'AppLink',
        component: () => import('../views/AppLink')
    },
    {
        path: '/web-download',
        name: 'WebDownload',
        component: () => import('../views/WebDownload')
    },
    {
        path: '/yanruyu/user-agreement',
        name: 'UserAgreement',
        component: () => import('../views/UserAgreement')
    },
    {
        path: '/user-agreement-android',
        name: 'UserAgreementAndroid',
        component: () => import('../views/UserAgreement-Android')
    },
    {
        path: '/yanruyu/privacy',
        name: 'Privacy',
        component: () => import('../views/Privacy')
    },
    {
        path: '/privacy-android',
        name: 'PrivacyAndroid',
        component: () => import('../views/Privacy-Android')
    },
    {
        path: '/privacy-android2',
        name: 'PrivacyAndroid2',
        component: () => import('../views/Privacy-Android2')
    },
    {
        path: '/i',
        name: 'InviteRegister',
        component: () => import('../views/InviteRegister')
    },
    {
        path: '/game/user-agreement',
        name: 'GameUserAgreement',
        component: () => import('../views/GameUserAgreement')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
