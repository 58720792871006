import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        apkUrl: 'https://www.mianjuyuehui.net/download/androidRelease.apk',
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})
